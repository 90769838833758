/* src/components/NewQueryForm.css */
.new-query-form {
    position: fixed; /* Use fixed positioning to overlay the form */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Translate to center */
    padding: 20px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 600px; /* Fixed width for better presentation */
    z-index: 1000; /* Ensure it appears on top of other content */
    display: flex;
    flex-direction: column;
}

.new-query-form h2 {
    margin-bottom: 20px;
}

.new-query-form input,
.new-query-form select {
    display: block;
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.new-query-form button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.new-query-form button:hover {
    background-color: #0056b3;
}

.parameter-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.parameter-row input {
    margin-right: 5px;
    flex: 1;
}

.delete-button {
    background-color: #dc3545;
}

.delete-button:hover {
    background-color: #c82333;
}

.add-button {
    background-color: #28a745;
    margin-bottom: 20px;
}

.add-button:hover {
    background-color: #218838;
}
