.view-query-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 30px;
}

/* Header container for search and create query button */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Left controls for search bar and filter dropdown */
.left-controls {
    display: flex;
    gap: 15px; /* Space between search and filter */
}

/* Search bar styles */
.search-bar {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 250px;
}

/* Filter dropdown styles */
.filter-dropdown {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Create New Query button styles */
.create-query-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.create-query-button:hover {
    background-color: #218838;
}

/* Tab buttons section */
.tab-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.tab-button {
    padding: 10px 20px;
    background-color: #87ceeb;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.tab-button:hover {
    background-color: #69b3e0;
}

.tab-content {
    margin-top: 20px;
}
