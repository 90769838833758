.request-details-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.request-info {
    margin-bottom: 20px;
}

.comments-section {
    margin-top: 30px;
}

.comment-box {
    margin-top: 20px;
}

/* Updated comment item to differentiate between my and other comments */
.comment-item {
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 15px;
    max-width: 80%;
}

/* Styles for the user's own comments */
.my-comment {
    background-color: #e1ffc7;
    text-align: right;
    margin-left: auto;
    border: 1px solid #b8e6a1;
    border-radius: 12px 12px 0 12px; /* Rounded right side for personal comments */
}

/* Styles for other users' comments */
.other-comment {
    background-color: #f0f0f0;
    text-align: left;
    margin-right: auto;
    border: 1px solid #ddd;
    border-radius: 12px 12px 12px 0; /* Rounded left side for others' comments */
}

.comment-item p {
    margin: 5px 0;
}

/* Larger textarea with better spacing */
textarea {
    width: 100%;
    height: 120px;
    padding: 15px;
    border-radius: 6px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    font-size: 14px;
    resize: none;
    outline: none;
    transition: border-color 0.3s ease;
}

textarea:focus {
    border-color: #28a745;
}

/* Primary button style */
button {
    padding: 12px 25px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover effect for buttons */
button:hover {
    background-color: #218838;
    transform: scale(1.02);
}

/* Disabled button style */
button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Add media query for mobile responsiveness */
@media (max-width: 600px) {
    .request-details-container {
        padding: 15px;
        max-width: 100%;
    }

    .comment-item {
        max-width: 100%;
    }

    button {
        width: 100%;
        padding: 15px;
    }
}
