/* Updated CSS for a more modern look */

/* Quote Details Container */
.quote-details-view {
    font-family: 'Roboto', sans-serif;
    padding: 30px;
    background-color: #f4f7fc;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 0 auto;
}

/* Tabs Container */
.tabs {
    display: flex;
    justify-content: space-around; /* More space between tabs */
    gap: 30px; /* Adds more spacing between buttons */
    margin-bottom: 30px;
}

/* Modern Tab Styling */
.tab {
    flex-grow: 1; /* Make buttons take more horizontal space */
    padding: 15px 0; /* Increase padding for better click area */
    background: linear-gradient(135deg, #6a11cb, #2575fc); /* Attractive gradient */
    color: white;
    font-size: 18px; /* Larger, more readable text */
    font-weight: bold;
    cursor: pointer;
    border-radius: 50px; /* Smooth rounded edges */
    border: none;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

/* Tab hover effect */
.tab:hover {
    background: linear-gradient(135deg, #5c0dbc, #1769ff); /* Darker gradient on hover */
    transform: translateY(-3px); /* Slight upward motion */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
}

/* Active tab */
.tab.active {
    background: linear-gradient(135deg, #ff7e5f, #feb47b); /* Distinct active gradient */
    transform: translateY(-5px); /* Higher lift for active state */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* Stronger shadow for active */
}

/* Tab content container */
.tab-content {
    margin-top: 20px;
    padding: 30px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
}

/* General button styling for all buttons */
button {
    display: inline-block;
    padding: 12px 25px;
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    margin-top: 20px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Button hover effect */
button:hover {
    background: linear-gradient(135deg, #ff8a70, #feab87);
    transform: translateY(-3px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Button active effect */
button:active {
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Loading and error states */
.loading {
    text-align: center;
    font-size: 18px;
    color: #888;
}

.error {
    text-align: center;
    font-size: 18px;
    color: #e74c3c;
}
