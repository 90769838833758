/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto+Slab:wght@400;500&display=swap');

/* Overall page layout */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f6f9;
  font-family: 'Poppins', sans-serif;
}

/* Container for the login form */
.login-container {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 350px;
  text-align: center;
}

/* Branding section */
.branding {
  margin-bottom: 1.5rem;
}

/* App name styling */
.app-name {
  font-family: 'Poppins', sans-serif; /* Main font */
  font-size: 32px;
  font-weight: 700;
  color: #333;
  letter-spacing: -1px;
  margin-bottom: 0;
  padding-bottom: 5px;
}

.app-name span {
  font-family: 'Roboto Slab', serif; /* Different font for 'AI' */
  font-weight: 500;
  color: #e67e22; /* Brand color for 'AI' */
  font-style: italic;
}

/* Tagline below the app name */
.tagline {
  color: #888;
  font-size: 14px;
  margin-top: -10px;
  
}

/* Form inputs */
.input-field {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #fafafa;
  transition: border-color 0.3s;
}

.input-field:focus {
  border-color: #007bff; /* Focus effect */
}

/* Login button */
.login-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #0056b3;
}

/* Error message */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

/* Signup link styling */
.signup-link {
  margin-top: 20px;
  color: #888;
}

.signup-link a {
  color: #007bff;
  text-decoration: none;
  font-weight: 600;
}

.signup-link a:hover {
  text-decoration: underline;
}
