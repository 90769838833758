/* src/pages/QueryParameters.css */
.query-parameters-view {
    padding: 20px; /* Add some padding around the container */
    background-color: #f9f9f9; /* Light background color for better readability */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

.field-row {
    display: flex; /* Flexbox for horizontal layout */
    align-items: center; /* Center items vertically */
    margin-bottom: 10px; /* Space between rows */
}

.field-input {
    flex: 1; /* Take up available space */
    padding: 10px; /* Padding for better touch target */
    margin-right: 10px; /* Space between input fields and buttons */
    border: 1px solid #ccc; /* Border styling */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Font size for readability */
}

.field-input:focus {
    border-color: #007bff; /* Change border color on focus */
    outline: none; /* Remove default outline */
}

.add-button, .delete-button {
    padding: 10px 15px; /* Padding for buttons */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    color: white; /* White text color */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Font size for buttons */
    transition: background-color 0.3s; /* Smooth transition for hover effects */
}

.add-button {
    background-color: #28a745; /* Green background for add button */
}

.add-button:hover {
    background-color: #218838; /* Darker green on hover */
}

.delete-button {
    background-color: #dc3545; /* Red background for delete button */
    margin-left: 10px; /* Space between delete button and input fields */
}

.delete-button:hover {
    background-color: #c82333; /* Darker red on hover */
}
