/* Main layout */
.app-container {
    display: flex;
    height: 100vh;
    background-color: #f8f9fa; /* Light background for the app */
    position: relative;
}

/* Sidebar */
.sidebar {
    width: 230px; /* Sidebar width */
    position: fixed;
    height: 100vh;
    background-color:#3498db; /* Sky Blue */
    color: white;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

/* Content area */
.content {
    margin-left: 260px; /* Space for sidebar */
    padding: 20px;
    background-color: #ffffff; /* White background for content */
    height: 100vh;
    overflow-y: auto;
    flex-grow: 1;
    padding-top: 40px; /* Give space for header */
}

/* Profile */
.profile-container {
    position: fixed;
    top: 10px;
    right: 10px; /* Align it to the right */
    z-index: 15;
}

.profile-button {
    background-color: #16a085; /* Modern teal */
    border-radius: 50%;
    padding: 12px;
    cursor: pointer;
    outline: none;
    border: none;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Add shadow to make it stand out */
}

.profile-button:hover {
    background-color: #1abc9c;
}

/* Profile dropdown */
.profile-dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 8px;
    z-index: 100;
    width: 280px;
}

.profile-details {
    margin-bottom: 15px;
    font-size: 14px;
}

.profile-details p {
    margin: 5px 0;
    font-weight: bold;
}

/* Styling for the logout button */
.logout-button {
    background-color: #d9534f;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}

.logout-button:hover {
    background-color: #c9302c;
}

/* Table Styles */
.query-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.query-table th, .query-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.query-table th {
    background-color: #34495e;
    color: white;
}

.query-table tr:nth-child(even) {
    background-color: #ecf0f1;
}

.query-table tr:hover {
    background-color: #bdc3c7;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .sidebar {
        width: 180px;
    }

    .content {
        margin-left: 180px;
    }

    .query-table th, .query-table td {
        padding: 8px;
    }
}
