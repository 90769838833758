/* Container for Get Quote page */
.quote-view {
    padding: 20px;
}

/* Header for the Create Quote button */
.header-container {
    display: flex;
    justify-content: flex-start;  /* Aligns the button to the left */
    margin-bottom: 10px;
    align-items: center;  /* Ensures proper vertical alignment */
}


/* Button to create a new quote request */
.create-quote-button {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.create-quote-button:hover {
    background-color: #218838;
}

/* Form styling for quote requests */
.quote-form {
    padding: 20px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    background-color: #f9f9f9;
}

.quote-form label {
    display: block;
    margin-bottom: 10px;
}

.quote-form input,
.quote-form select,
.quote-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

/* Styling for buttons in the form */
.include-variables-button,
.submit-quote-button,
.cancel-button {
    padding: 10px 15px;
    margin-right: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.include-variables-button:hover {
    background-color: #0056b3;
}

.submit-quote-button {
    background-color: #28a745;
}

.submit-quote-button:hover {
    background-color: #218838;
}

.cancel-button {
    background-color: #dc3545;
}

.cancel-button:hover {
    background-color: #c82333;
}

/* Table styling for Get Quote table */
.quote-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.quote-table th, .quote-table td {
    padding: 12px;
    text-align: left;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.quote-table th {
    background-color: #3498db;  /* Same color as final quote table */
    color: white;
    font-weight: bold;
}

.quote-table td {
    background-color: #f9f9f9;
}

/* Hover effect for rows */
.quote-table tr:hover td {
    background-color: #eaf6ff;
}

/* Alternate row colors for better readability */
.quote-table tr:nth-child(even) td {
    background-color: #f4f4f4;
}

/* Button styles inside the table */
.quote-table button {
    padding: 8px 12px;
    border: none;
    background-color: #3498db;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.quote-table button:hover {
    background-color: #2980b9;
}

/* Additional styling for table headers */
.quote-table th:first-child {
    border-top-left-radius: 10px;
}

.quote-table th:last-child {
    border-top-right-radius: 10px;
}

.quote-table td:first-child {
    border-bottom-left-radius: 10px;
}

.quote-table td:last-child {
    border-bottom-right-radius: 10px;
}
/* Modal overlay */
/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
}

/* Modal content */
.modal-content {
    background-color: white; /* White background for the modal */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Space inside the modal */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    width: 400px; /* Set width of the modal */
}

/* Modal form labels */
.modal-content label {
    display: block; /* Make labels block level */
    margin-bottom: 10px; /* Space between labels and inputs */
}

/* Modal input fields */
.modal-content input,
.modal-content textarea {
    width: 100%; /* Full width */
    padding: 8px; /* Padding for inputs */
    margin-bottom: 15px; /* Space below inputs */
    border: 1px solid #ccc; /* Border around inputs */
    border-radius: 4px; /* Rounded corners */
}

/* Modal buttons */
.submit-quote-button,
.cancel-button {
    margin-right: 10px; /* Space between buttons */
}

/* Button styling */
.submit-quote-button {
    background-color: #007bff; /* Blue background for submit button */
    color: white; /* White text */
    border: none; /* No border */
    padding: 10px 15px; /* Padding for button */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
}

.cancel-button {
    background-color: #dc3545; /* Red background for cancel button */
    color: white; /* White text */
    border: none; /* No border */
    padding: 10px 15px; /* Padding for button */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
}

/* Hover effects for buttons */
.submit-quote-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.cancel-button:hover {
    background-color: #c82333; /* Darker red on hover */
}
/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
}

/* Modal content */
.modal-content {
    background-color: white; /* White background for the modal */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Space inside the modal */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    width: 600px; /* Increased width of the modal */
    max-width: 90%; /* Ensures the modal does not exceed screen width on smaller devices */
}

/* Modal form labels */
.modal-content label {
    display: block; /* Make labels block level */
    margin-bottom: 10px; /* Space between labels and inputs */
}

/* Modal input fields */
.modal-content input,
.modal-content textarea {
    width: 100%; /* Full width */
    padding: 8px; /* Padding for inputs */
    margin-bottom: 15px; /* Space below inputs */
    border: 1px solid #ccc; /* Border around inputs */
    border-radius: 4px; /* Rounded corners */
}

/* Modal buttons */
.submit-quote-button,
.cancel-button {
    margin-right: 10px; /* Space between buttons */
}

/* Button styling */
.submit-quote-button {
    background-color: #007bff; /* Blue background for submit button */
    color: white; /* White text */
    border: none; /* No border */
    padding: 10px 15px; /* Padding for button */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
}

.cancel-button {
    background-color: #dc3545; /* Red background for cancel button */
    color: white; /* White text */
    border: none; /* No border */
    padding: 10px 15px; /* Padding for button */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
}

/* Hover effects for buttons */
.submit-quote-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.cancel-button:hover {
    background-color: #c82333; /* Darker red on hover */
}
