.quotations-view-container {
    padding: 20px;
  }
  
  .tab-buttons {
    margin-bottom: 20px;
  }
  
  .tab-button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .tab-button.active {
    background-color: #3498db;
    color: white;
  }
  
  .quotation-details, .chat-with-agent {
    border: 1px solid #ddd;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
  }
  