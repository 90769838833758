/* Sidebar container */
.sidebar {
    width: 240px; /* Increased width for more space */
    background-color: #3498db; /* Consistent background color */
    color: white;
    height: 100vh;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    position: fixed; /* Stick to the left */
}

/* Sidebar title styling */
.sidebar-title {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 30px;
    color: white;
    letter-spacing: -1px;
}

.sidebar-title span {
    font-family: 'Roboto Slab', serif; /* Different font for 'AI' */
    font-weight: 500;
    color: #e67e22; /* Highlight 'AI' in different color */
    font-style: italic;
}

/* Sidebar ul styling */
.sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Sidebar list items */
.sidebar ul li {
    margin-bottom: 20px; /* Spacing between options */
}

/* Sidebar links */
.sidebar ul li a {
    display: flex; /* Flexbox to align icons and text */
    align-items: center; /* Align items vertically */
    padding: 12px 15px; /* Increased padding for larger clickable area */
    font-size: 18px; /* Larger font size */
    color: white; /* White text */
    text-decoration: none; /* Remove underline */
    border-radius: 8px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth hover transition */
}

/* Active sidebar link styling */
.sidebar ul li a.active {
    background-color: #1abc9c; /* Highlight active item */
    font-weight: bold; /* Make active option bold */
    color: white; /* Keep text white */
}

/* Hover effect for sidebar links */
.sidebar ul li a:hover {
    background-color: #2980b9; /* Change background on hover */
}

/* Icon styling in sidebar */
.sidebar ul li a i {
    margin-right: 10px; /* Space between icon and text */
    font-size: 20px; /* Slightly larger icons */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .sidebar {
        width: 180px; /* Narrower sidebar for small screens */
        padding: 15px;
    }

    .sidebar ul li a {
        font-size: 16px; /* Smaller font on smaller screens */
        padding: 10px 12px;
    }

    .sidebar-title {
        font-size: 20px; /* Smaller title for small screens */
    }
}
