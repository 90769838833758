.final-quote-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.create-quote-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.create-quote-button:hover {
    background-color: #45a049;
}

.quote-form {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input, select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.parameter-input, .breakdown-input {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.delete-parameter-button {
    background-color: #e74c3c;
    color: white;
    padding: 6px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.delete-parameter-button:hover {
    background-color: #c0392b;
}

.submit-quote-button, .cancel-button {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    transition: background-color 0.3s ease;
}

.submit-quote-button:hover {
    background-color: #2980b9;
}

.cancel-button {
    background-color: #e74c3c;
}

.cancel-button:hover {
    background-color: #c0392b;
}

/* Enhanced table styling */
.quote-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.quote-table th, .quote-table td {
    padding: 12px;
    text-align: left;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.quote-table th {
    background-color: #3498db;
    color: white;
    font-weight: bold;
}

.quote-table td {
    background-color: #f9f9f9;
}

.quote-table tr:hover td {
    background-color: #eaf6ff;
}

/* Alternate row colors */
.quote-table tr:nth-child(even) td {
    background-color: #f4f4f4;
}

/* Styling buttons in the table */
.quote-table button {
    padding: 8px 12px;
    border: none;
    background-color: #3498db;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.quote-table button:hover {
    background-color: #2980b9;
}

/* Additional styling for table headers */
.quote-table th:first-child {
    border-top-left-radius: 10px;
}

.quote-table th:last-child {
    border-top-right-radius: 10px;
}

.quote-table td:first-child {
    border-bottom-left-radius: 10px;
}

.quote-table td:last-child {
    border-bottom-right-radius: 10px;
}
