.request-view {
    padding: 20px;
}

.header-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.create-request-button {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.create-request-button:hover {
    background-color: #218838;
}

.request-form {
    padding: 20px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    background-color: #f9f9f9;
}

.request-form label {
    display: block;
    margin-bottom: 10px;
}

.request-form select,
.request-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.include-variables-button,
.submit-request-button,
.cancel-button {
    padding: 10px 15px;
    margin-right: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.include-variables-button:hover {
    background-color: #0056b3;
}

.submit-request-button {
    background-color: #28a745;
}

.submit-request-button:hover {
    background-color: #218838;
}

.cancel-button {
    background-color: #dc3545;
}

.cancel-button:hover {
    background-color: #c82333;
}

/* Enhanced table styling (matching final quote table) */
.request-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.request-table th, .request-table td {
    padding: 12px;
    text-align: left;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.request-table th {
    background-color: #3498db;  /* Same color as final quote table */
    color: white;
    font-weight: bold;
}

.request-table td {
    background-color: #f9f9f9;
}

.request-table tr:hover td {
    background-color: #eaf6ff;
}

/* Alternate row colors */
.request-table tr:nth-child(even) td {
    background-color: #f4f4f4;
}

/* Styling buttons in the table */
.request-table button {
    padding: 8px 12px;
    border: none;
    background-color: #3498db;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.request-table button:hover {
    background-color: #2980b9;
}

/* Additional styling for table headers */
.request-table th:first-child {
    border-top-left-radius: 10px;
}

.request-table th:last-child {
    border-top-right-radius: 10px;
}

.request-table td:first-child {
    border-bottom-left-radius: 10px;
}

.request-table td:last-child {
    border-bottom-right-radius: 10px;
}
