/* Agent Details View */
.agents-details-view {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    margin: 30px auto;
}

h2 {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

/* Enhanced table styling */
.agents-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.agents-table th, .agents-table td {
    padding: 12px;
    text-align: left;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.agents-table th {
    background-color: #3498db; /* Blue background like final quote table */
    color: white;
    font-weight: bold;
}

.agents-table td {
    background-color: #f9f9f9;
}

/* Hover effect for rows */
.agents-table tr:hover td {
    background-color: #eaf6ff;
}

/* Alternate row colors */
.agents-table tr:nth-child(even) td {
    background-color: #f4f4f4;
}

/* Styling buttons (if applicable) in the table */
.agents-table button {
    padding: 8px 12px;
    border: none;
    background-color: #3498db;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.agents-table button:hover {
    background-color: #2980b9;
}

/* Additional styling for table headers */
.agents-table th:first-child {
    border-top-left-radius: 10px;
}

.agents-table th:last-child {
    border-top-right-radius: 10px;
}

.agents-table td:first-child {
    border-bottom-left-radius: 10px;
}

.agents-table td:last-child {
    border-bottom-right-radius: 10px;
}
/* Select Agents button */
.select-agents-button {
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
}

.select-agents-button:hover {
    background-color: #2980b9;
}

/* Selection action buttons (Send to Agents and Cancel) */
.selection-actions {
    margin-bottom: 20px;
}

.send-to-agents-button,
.cancel-selection-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.send-to-agents-button {
    background-color: #28a745;
    color: white;
}

.send-to-agents-button:disabled {
    background-color: #c8e6c9;
    cursor: not-allowed;
}

.cancel-selection-button {
    background-color: #dc3545;
    color: white;
}

.send-to-agents-button:hover {
    background-color: #218838;
}

.cancel-selection-button:hover {
    background-color: #c82333;
}
