/* General body styling */
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

/* Container to hold both sidebar and main content */
.container {
    display: flex; /* Use flexbox for layout */
}

/* Sidebar styling */
.sidebar {
    width: 250px; /* Set a fixed width for the sidebar */
    background-color: #f4f4f4; /* Example background color */
    height: 100vh; /* Full height of the viewport */
    padding: 20px; /* Add padding for aesthetics */
    position: fixed; /* Fix the sidebar in place */
    overflow-y: auto; /* Enable scrolling if content exceeds height */
}

/* Main content styling */
.main-content {
    margin-left: 250px; /* Set margin equal to sidebar width */
    padding: 20px; /* Add padding for main content */
    flex: 1; /* Allow it to expand and fill remaining space */
}
/* styles.css */

/* Table container styles */
.table-container {
    margin-top: 20px; /* Space above the table */
    overflow-x: auto; /* Allow horizontal scroll if needed */
}

/* Table styles */
table {
    width: 100%; /* Full width of the container */
    border-collapse: collapse; /* Combine borders of adjacent cells */
    margin-top: 20px; /* Space above the table */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

/* Header styles */
th, td {
    padding: 12px; /* Padding inside cells */
    text-align: left; /* Align text to the left */
    border-bottom: 1px solid #ddd; /* Light bottom border for rows */
}

/* Header cell styles */
th {
    background-color: #007bff; /* Header background color */
    color: white; /* Header text color */
}

/* Hover effect for rows */
tbody tr:hover {
    background-color: #f1f1f1; /* Highlight row on hover */
}

/* Button styles */
button {
    background-color: #28a745; /* Button background color */
    color: white; /* Button text color */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    padding: 8px 12px; /* Padding inside button */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition */
    margin-right: 5px; /* Space between buttons */
}

button:hover {
    background-color: #218838; /* Darken button on hover */
}

button:disabled {
    background-color: #ccc; /* Disabled button color */
    cursor: not-allowed; /* Not allowed cursor */
}
