/* Profile Container */
.profile-container {
    position: relative;
    margin-left: auto;
    margin-right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

/* Profile Avatar */
.profile-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #3498db; /* Modern blue background */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
}

.profile-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the avatar image fits well */
}

.default-avatar {
    font-size: 24px;
    color: #fff;
    font-weight: bold;
}

/* Hover effect for profile icon */
.profile-avatar:hover {
    transform: scale(1.1); /* Slight scaling effect on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
}

/* Dropdown Menu */
.profile-dropdown {
    position: absolute;
    top: 60px; /* Adjust based on avatar size */
    right: 0;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05); /* Light border for subtle separation */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); /* Subtle shadow for dropdown */
    padding: 20px;
    z-index: 1000;
    width: 280px; /* Fixed width for a compact look */
    border-radius: 15px; /* Smooth, modern rounded corners */
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.4s ease;
    visibility: hidden;
    font-family: 'Poppins', sans-serif; /* Modern font */
}

/* Show dropdown when the avatar is clicked */
.profile-container .profile-dropdown {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

/* Profile Details */
.profile-details {
    color: #333;
    margin-bottom: 20px;
    font-size: 14px;
}

.profile-details p {
    margin: 8px 0;
    color: #444;
}

.profile-details p strong {
    color: #1da1f2; /* Modern accent color for bold labels */
}

/* Logout Button */
.logout-button {
    background-color: #ff4c4c;
    color: white;
    padding: 12px 16px;
    border: none;
    border-radius: 50px; /* Rounded pill-shaped button */
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Space between icon and text */
}

.logout-button:hover {
    background-color: #e03b3b; /* Darker red on hover */
    box-shadow: 0 4px 15px rgba(255, 76, 76, 0.5); /* Shadow effect on hover */
}

.logout-button .fa-sign-out-alt {
    font-size: 16px;
}

/* Smooth transition when dropdown appears */
.profile-dropdown {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* Optional: Add slight shadow/glow to the avatar on hover */
.profile-avatar:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Optional: Avatar transition when dropdown is open */
.profile-container:hover .profile-avatar {
    transform: scale(1.1); /* Scaling effect */
}
