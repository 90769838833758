.active-queries .query-table {
    width: 100%; /* Full width */
    border-collapse: separate; /* Keep borders separated for shadow effect */
    border-spacing: 0 10px; /* Space between rows */
    margin-top: 20px; /* Add top margin */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05); /* Soft shadow for table */
}

.active-queries .query-table th, 
.active-queries .query-table td {
    padding: 12px; /* Increased padding for a cleaner look */
    text-align: left; /* Align text to the left */
    background-color: #ffffff; /* White background for cells */
    border-bottom: 1px solid #ddd; /* Subtle border at the bottom of each cell */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05); /* Shadow effect around rows */
}

.active-queries .query-table th {
    background-color: #3498db; /* Consistent header background color */
    color: white; /* White text for headers */
    font-weight: bold; /* Bold text for headers */
}

.active-queries .query-table td {
    background-color: #f9f9f9; /* Light background for table cells */
}

.active-queries .query-table tr:hover td {
    background-color: #eaf6ff; /* Light blue background on hover for rows */
}

/* Alternate row colors */
.active-queries .query-table tr:nth-child(even) td {
    background-color: #f4f4f4; /* Slightly darker background for alternate rows */
}

/* Styling buttons in the table */
.active-queries .query-table button {
    padding: 8px 12px;
    border: none;
    background-color: #3498db;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.active-queries .query-table button:hover {
    background-color: #2980b9; /* Darker blue on hover */
}

/* Additional styling for table headers */
.active-queries .query-table th:first-child {
    border-top-left-radius: 10px; /* Rounded corners for the first header cell */
}

.active-queries .query-table th:last-child {
    border-top-right-radius: 10px; /* Rounded corners for the last header cell */
}

.active-queries .query-table td:first-child {
    border-bottom-left-radius: 10px; /* Rounded corners for the first cell in the row */
}

.active-queries .query-table td:last-child {
    border-bottom-right-radius: 10px; /* Rounded corners for the last cell in the row */
}
/* Styles for the header container holding filters and create button */
.header-container {
    display: flex;
    justify-content: space-between; /* Space between filters and the create button */
    align-items: center; /* Align items vertically in the center */
    margin-bottom: 20px; /* Add some space below the header */
}

/* Styling for the Create New Query button container */
.create-query-button-container {
    margin-left: auto; /* Push the button to the far right */
}

.create-query-button {
    background-color: #3498db; /* Blue background */
    color: white; /* White text */
    padding: 10px 20px; /* Add padding for a larger button */
    border: none;
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    font-size: 16px; /* Font size */
    transition: background-color 0.3s ease;
}

/* Hover effect for Create New Query button */
.create-query-button:hover {
    background-color: #2980b9; /* Darker blue on hover */
}
