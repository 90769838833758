
.query-table {
    width: 100%; /* Full width */
    border-collapse: separate; /* Separate borders for visual clarity */
    border-spacing: 0 10px; /* Space between rows */
    margin-top: 20px; /* Add top margin */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Soft shadow for table */
  }
  
  .query-table th, .query-table td {
    padding: 12px; /* Increased padding for a cleaner look */
    text-align: left; /* Align text to the left */
    background-color: #ffffff; /* White background for cells */
    border-bottom: 1px solid #ddd; /* Subtle border at the bottom of each cell */
  }
  
  .query-table th {
    background-color: #007bff; /* Blue background for headers */
    color: white; /* White text for headers */
    font-weight: bold; /* Bold text for headers */
  }
  
  .query-table td {
    background-color: #f9f9f9; /* Light background for table cells */
  }
  
  .query-table tr:hover td {
    background-color: #eaf6ff; /* Light blue background on hover for rows */
  }
  
  /* Alternate row colors */
  .query-table tr:nth-child(even) td {
    background-color: #f1f1f1; /* Slightly darker background for alternate rows */
  }
  
  /* Styling buttons in the table */
  .query-table button {
    padding: 8px 12px;
    border: none;
    background-color: #007bff; /* Blue button */
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .query-table button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  /* Additional styling for table headers */
  .query-table th:first-child {
    border-top-left-radius: 10px; /* Rounded corners for the first header cell */
  }
  
  .query-table th:last-child {
    border-top-right-radius: 10px; /* Rounded corners for the last header cell */
  }
  
  .query-table td:first-child {
    border-bottom-left-radius: 10px; /* Rounded corners for the first cell in the row */
  }
  
  .query-table td:last-child {
    border-bottom-right-radius: 10px; /* Rounded corners for the last cell in the row */
  }
  