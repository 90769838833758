/* General styling for the quote details page */
.quote-details-view {
    padding: 30px;
    background-color: #f9fafb;
    border-radius: 15px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1); /* Softer shadow for modern card effect */
    max-width: 800px; /* Keep the card width manageable */
    margin: 40px auto; /* Center the card on the page */
    font-family: 'Roboto', sans-serif;
}

/* Header Styling */
h2 {
    text-align: center;
    font-size: 28px; /* Larger heading */
    color: #2c3e50;
    font-weight: bold;
    margin-bottom: 30px;
}

/* Styling for the card containing the details */
.quote-card {
    background: white;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1); /* Soft shadow for card */
    margin-bottom: 20px;
}

/* Styling for each quote detail item */
.quote-card p {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
}

strong {
    color: #2c3e50; /* Darker color for emphasis */
    font-weight: 600;
}

/* Styling for the editable email textarea */
.email-edit {
    margin-top: 30px; /* More space before textarea */
}

textarea {
    height: 200px; /* Increased height */
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #f5f7fa;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

textarea:focus {
    border-color: #5c7cfa;
    box-shadow: 0 4px 20px rgba(92, 124, 250, 0.2);
    outline: none;
}

/* Save button styling */
.save-button {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 30px;
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

/* Save button hover effect */
.save-button:hover {
    background: linear-gradient(135deg, #5b0db8, #1460f0);
    transform: translateY(-3px); /* Slight upward motion on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow on hover */
}

/* Loading state */
.loading {
    text-align: center;
    font-size: 22px;
    color: #888;
    padding: 20px;
}
